import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment-mini";
import useIsMountedRef from "hooks/useIsMountedRef";
import Container from "components/Container";
import GridElement from "components/GridElement";
import ArticleThumbnail from "components/partials/ArticleThumbnail";
import Media from "css/Media";
import ThumbnailList from "../partials/ThumbnailList";

export const articleListQuery = (pageType) => `
  ... on ${pageType}_Modules_Builder_ArticleListModule {
    fieldGroupName
  }
`;

const moduleQuery = (pageType, capitalizedPageType, slug) => `
  query MyQuery {
    ${pageType}(id: "${slug}", idType: URI) {
      modules{
        builder{
          ... on ${capitalizedPageType}_Modules_Builder_ArticleListModule {
            fieldGroupName
            select
            title
            selectArticles {
              ... on NewsArticle {
                title
                uri
                thumbnailData {
                  description
                  featuredImage {
                    mediaItemUrl
                  }
                }
              }
              ... on Page {
                title
                uri
                thumbnailData {
                  description
                  featuredImage {
                    mediaItemUrl
                  }
                }
              }
            }
            chapter {
              name
            }
            tags {
              name
            }
          }
        }
      }
    }
  }
`;

const articleQuery = (day, month, year, term, terms, lang) => `
  query MyQuery {
    news(where: {
      taxQuery: {
        relation: AND, 
        taxArray: {
          taxonomy: ${term === "chapter" ? "CHAPTERTAG" : "PERFORMANCETYPETAG"}
          field: NAME,
          terms: [${terms.map((t) => `"${t}"`).join(",")}],
        }
      },
      orderby: {
        field: DATE,
        order: ASC,
      },
      dateQuery: {
        column: DATE, 
        before: {
          day: ${day}, 
          month: ${month}, 
          year: ${year}
        }
      },
      language: ${lang.toUpperCase()}
    }) {
      edges {
        node {
          title
          uri
          id
          thumbnailData {
            description
            featuredImage {
              mediaItemUrl
            }
          }
          translation(language: EN) {
            id
          }
        }
      }
    }
  }
`;

async function getModuleData(pageType, slug) {
  let capitalizedPageType = pageType[0].toUpperCase() + pageType.substring(1);
  pageType =
    pageType === "NewsArticle" ? "newsArticle" : pageType.toLowerCase();

  const moduleResp = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: moduleQuery(pageType, capitalizedPageType, slug),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  return moduleResp;
}

async function getArticleData(term, terms, lang) {
  const year = parseInt(moment(new Date()).format("YYYY"));
  const month = parseInt(moment(new Date()).format("MM"));
  const day = parseInt(moment(new Date()).format("DD"));

  const articleResp = await fetch(process.env.REACT_APP_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: articleQuery(day, month, year, term, terms, lang),
    }),
  })
    .then((res) => res.json())
    .then(({ data }) => data);
  return articleResp;
}

export default function ArticleListModule(props) {
  //params needed to specify query params
  let lang = useSelector((state) => state.lang);
  let slug =
    window.location.pathname === "/"
      ? lang === "no"
        ? "home"
        : "home-2"
      : window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1
        );

  const [articles, setArticles] = useState();
  const [moduleData, setModuleData] = useState(null);
  const [data, setData] = useState(null);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    //we run this query first to get user input if events should be listed by chapter
	getModuleData(props.pageType, slug).then((res) => isMountedRef.current && setModuleData(res));
  }, [props.pageType, slug, isMountedRef]);

  useEffect(() => {
    //runs after we fetch moduleData
    let page = props.pageType[0].toLowerCase() + props.pageType.substring(1);
    if (moduleData && moduleData[page]) {
      //we filter the array to only contain the module we're looking for
      let module = moduleData[page].modules.builder.filter(
        (m) =>
          m.fieldGroupName ===
          `${props.pageType}_Modules_Builder_ArticleListModule`
      );
      //we only run second query if events should be populated automatically
      if (module[props.typeIndex]?.select === "manual") {
		isMountedRef.current && setArticles(module[props.typeIndex].selectArticles);
      } else {
		if(module.length < 1) return;
        let term = module[props.typeIndex].select;
        let terms = [module[props.typeIndex][term].name];
        getArticleData(term, terms, lang).then((res) =>
          handleTaxonomyFilter(res)
        );
      }
	  isMountedRef.current && setData(module[props.typeIndex]);
    }
  }, [moduleData, lang, props.pageType, props.typeIndex, isMountedRef]);

  const handleTaxonomyFilter = (data) => {
    let news = [];
    data.news.edges.map((item) => news.push(item.node));
    news = news.slice(0, 3);
    setArticles(news);
  };
  if (!articles || articles.length === 0) return null;
  return (
    <Wrapper>
      {data.title ? (
        <Container>
          <GridElement
            colStart={{ default: 2, s: 5 }}
            colEnd={{ default: 13, s: 24 }}
          >
            <h2>{data.title}</h2>
          </GridElement>
        </Container>
      ) : null}
      <ThumbnailList mobileFullWidth>
        {articles.map((item, idx) => {
          return (
            <ArticleThumbnail
              title={item.title}
              image={item.thumbnailData.featuredImage.mediaItemUrl}
              descripton={item.thumbnailData.description}
              url={item.uri}
              key={idx}
            />
          );
        })}
      </ThumbnailList>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  h2 {
    ${(props) => props.theme.fonts.h2}
    margin-bottom: 32px;

    ${Media.greaterThan("s")`
            margin-bottom: 78px;
        `}
  }
  ${Media.greaterThan("s")`
        margin-bottom: 36px;
    `}
`;
